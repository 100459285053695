import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Header from './header';
import Footer from './footer';

const ContentWrapper = styled.div`
  > main {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  section {
    max-width: 1200px;
  }
`;

const Layout = ({ children }) => {
  return (
    <>
      <Header />
      <ContentWrapper>
        <main>{children}</main>
      </ContentWrapper>
      <Footer />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
