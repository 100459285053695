import React from 'react';
import { ANoStyleLight, TripAdvisorLogo, FacebookLogo } from './page-elements';
import styled from 'styled-components';
import { OutboundButton, paragraphMixin } from '../theme/mixins';
import { OutboundLink } from 'gatsby-plugin-google-gtag';

const FooterWrapper = styled.header`
  display: flex;
  flex-direction: column;
  background: ${(props) => props.theme.color.header};
  color: ${(props) => props.theme.color.white};
  font-size: ${(props) => props.theme.fontSize.sm};
`;

const ContentSection = styled.section`
  align-self: center;
  max-width: 1200px;
  width: calc(100% - 200px);

  padding: 30px 0px;
  margin: 0px 100px;

  @media only screen and (max-width: 1000px) {
    margin: 0px 32px;
    width: calc(100% - 64px);
  }
`;

const GridContainer2 = styled.div`
  display: grid !important;
  grid-template-columns: 1fr 1fr;
  grid-gap: 24px;

  width: 100%;

  @media only screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const GridItem = styled.div`
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: 768px) {
    align-items: center;
  }
  svg {
    &:hover {
      transform: scale(1.1);
      transition: transform 0.2s;
    }
  }
`;

const DivRight = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  height: 100%;

  a {
    margin-top: auto;
  }

  @media only screen and (max-width: 768px) {
    align-items: center;

    a {
      margin-top: 24px;
    }
  }
`;

const BusinessDetails = styled.div`
  display: flex;
  background: rgba(196, 196, 196, 0.1);
  justify-content: center;

  padding: ${(props) => props.theme.spacing['4']};
  b {
    ${paragraphMixin}
    font-weight: ${(props) => props.theme.fontWeight.light};
  }
`;

const Footer = () => (
  <FooterWrapper>
    <ContentSection>
      <GridContainer2>
        <GridItem>
          <div>
            <span style={{ marginRight: 20 }}>
              <OutboundLink
                href="https://www.facebook.com/PeterboroughCaravanPark/"
                target="_blank"
                rel="noreferrer"
              >
                <FacebookLogo />
              </OutboundLink>
            </span>
            <span>
              <OutboundLink
                href="https://www.tripadvisor.com.au/Hotel_Review-g499722-d4065950-Reviews-Peterborough_Caravan_Park-Peterborough_South_Australia.html?m=19905"
                target="_blank"
                rel="noreferrer"
              >
                <TripAdvisorLogo />
              </OutboundLink>
            </span>
          </div>
          <br />
          <ANoStyleLight href="tel://0886512545">(08) 8651 2545</ANoStyleLight>
          <ANoStyleLight href="mailto:pbcp5422@gmail.com">pbcp5422@gmail.com</ANoStyleLight>
        </GridItem>

        <GridItem>
          <DivRight>
            <OutboundButton href="/#booking">Book now</OutboundButton>
            <ANoStyleLight href="https://goo.gl/maps/KihLdvQQSAqBKum57" target="_blank">
              36 Grove St, Peterborough, South Australia, 5422 Australia
            </ANoStyleLight>
          </DivRight>
        </GridItem>
      </GridContainer2>
    </ContentSection>

    <BusinessDetails>
      <p>Peterborough Caravan Park {new Date().getFullYear()}</p>
    </BusinessDetails>
  </FooterWrapper>
);

export default Footer;
