import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { useSiteMetadata } from '../hooks/useSiteMetadata';
import { slide as Menu } from 'react-burger-menu';

const linkHoverMixin = css`
  &:hover {
    color: #01b3ff;
    transition: color 0.2s;
  }
`;

const burgerMenuMixin = css`
  .bm-burger-button {
    display: none;
    position: absolute;
    width: 36px;
    height: 30px;
    right: 24px;
    top: 42px;

    @media only screen and (max-width: 1100px) {
      display: block;
    }
  }

  .bm-burger-bars {
    background: rgba(255, 255, 255, 0.9);
  }

  .bm-burger-bars-hover {
    opacity: 0.7;
  }

  .bm-cross-button {
    height: 24px;
    width: 24px;
  }

  .bm-cross {
    background: #bdc3c7;
  }

  .bm-menu-wrap {
    position: fixed;
    height: 100%;
  }

  .bm-menu {
    background: ${(props) => props.theme.color.header};
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
  }

  .bm-morph-shape {
    fill: #373a47;
  }

  .bm-item-list {
    color: #b8b7ad;
    padding: 0.8em;
  }

  .bm-item {
    display: inline-block;
  }

  .bm-overlay {
    background: rgba(0, 0, 0, 0.3);
  }

  .bm-item {
    display: inline-block;

    color: #d1d1d1;
    margin-bottom: 10px;
    text-align: left;
    text-decoration: none;
    transition: color 0.2s;
  }

  .bm-item:hover {
    ${linkHoverMixin}
  }
`;

const HeaderWrapper = styled.header`
  background: ${(props) => props.theme.color.header};

  ${burgerMenuMixin}
`;

const Headline = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1.45rem 1.0875rem;
`;

const StyledLink = styled(Link)`
  color: white;
  text-decoration: none;
  padding: 20px;
  ${linkHoverMixin}
`;

const LinkWrapper = styled.div`
  align-self: center;
  a {
    font-size: ${(props) => props.theme.fontSize.lg};
  }

  @media only screen and (max-width: 1100px) {
    ${StyledLink} {
      display: none;
    }
  }

  /* Fix Safari bug - unable to click links */
  z-index: 1000;
`;

const HeaderTitle = styled(Link)`
  color: white;
  text-decoration: none;
  padding: 20px;
  font-size: ${(props) => props.theme.fontSize['lg']};
  font-weight: bold;
  margin-right: 24px;

  /* Fix Safari bug - unable to click links */
  z-index: 100;
`;

const Header = ({ pageTitle = '' }) => {
  const { title } = useSiteMetadata();
  return (
    <HeaderWrapper>
      <Menu disableAutoFocus right className="burger-menu">
        <StyledLink to="/">Home</StyledLink>
        <StyledLink to="/accommodation">Accommodation</StyledLink>
        <StyledLink to="/facilities-map">Facilities &amp; Map</StyledLink>
        <StyledLink to="/things-in-peterborough">Things in Peterborough</StyledLink>
      </Menu>
      <Headline>
        <HeaderTitle to="/">{title}</HeaderTitle>
        <LinkWrapper>
          <StyledLink to="/">Home</StyledLink>
          <StyledLink to="/accommodation">Accommodation</StyledLink>
          <StyledLink to="/facilities-map">Facilities &amp; Map</StyledLink>
          <StyledLink to="/things-in-peterborough">Things in Peterborough</StyledLink>
        </LinkWrapper>
      </Headline>
    </HeaderWrapper>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
