import { css } from 'styled-components';
import styled from 'styled-components';
import { OutboundLink } from 'gatsby-plugin-google-gtag';

export const headingMixin = css`
  font-weight: bold;
  font-size: ${(props) => props.theme.fontSize['4xl']};

  @media only screen and (max-width: 768px) {
    font-size: ${(props) => props.theme.fontSize['2xl']};
  }
`;

export const subheadingMixin = css`
  font-weight: ${(props) => props.theme.fontWeight.semibold};
  font-size: ${(props) => props.theme.fontSize['3xl']};

  @media only screen and (max-width: 768px) {
    font-size: ${(props) => props.theme.fontSize['xl']};
  }
`;

export const paragraphMixin = css`
  font-style: normal;
  font-weight: normal;
  font-size: 18px;

  @media only screen and (max-width: 768px) {
    font-size: 16px;
  }
`;

const buttonStyleMixin = css`
  color: ${(props) => props.theme.color.white};
  &:hover {
    filter: contrast(130%);
    transform: scale(1.05);
  }
  transition: all 0.2s;
  background-color: #00abfa;
  padding: 15px 52px;
  font-size: 16px;
  border-radius: 25px;
  text-align: center;
  display: inline-block;
  border: none;
  cursor: pointer;
  text-decoration: none;
  white-space: nowrap;
`;

export const Button = styled.a`
  ${buttonStyleMixin}
`;

export const OutboundButton = styled(OutboundLink)`
  ${buttonStyleMixin}
`;
