import React from 'react';
import styled from 'styled-components';
import { OutboundLink } from 'gatsby-plugin-google-gtag';

export const ANoStyleDark = styled(OutboundLink)`
  text-decoration: none;
  color: ${(props) => props.theme.color.grey[900]};
`;

export const ANoStyleLight = styled(OutboundLink)`
  text-decoration: none;
  color: ${(props) => props.theme.color.white};
  &:hover {
    color: #01b3ff;
    transition: color 0.2s;
  }
`;

export const Button = styled.a`
  color: ${(props) => props.theme.color.white};
  &:hover {
    filter: contrast(130%);
    transform: scale(1.05);
  }
  transition: all 0.2s;

  background-color: #00abfa;
  padding: 15px 52px;
  font-size: 16px;
  border-radius: 25px;
  text-align: center;
  display: inline-block;
  border: none;
  cursor: pointer;
  text-decoration: none;
  white-space: nowrap;
`;

const Svg = styled.svg`
  width: 67px;
  height: 67px;
`;

// Cloud SVG - standard
export const Cloud = ({ className }) => (
  <Svg viewBox="0 0 24 24" className={className}>
    <path
      fill="currentColor"
      d="M19.47,10.54A6,6,0,0,0,14,7a5.82,5.82,0,0,0-1.39.18,5,5,0,0,0-9,2A3,3,0,0,0,4.5,15h1a4,4,0,0,0,0,.5A3.5,3.5,0,0,0,9,19h9.17a4.33,4.33,0,0,0,1.3-8.46ZM4.5,13a1,1,0,0,1,0-2,1,1,0,0,0,1-1,3,3,0,0,1,3-3,3,3,0,0,1,2.22,1,6,6,0,0,0-2.66,4.13,3.49,3.49,0,0,0-1.5.87Zm13.67,4H9a1.5,1.5,0,0,1,0-3,1,1,0,0,0,1-1,4,4,0,0,1,7.78-1.29,1,1,0,0,0,.78.67A2.33,2.33,0,0,1,18.17,17Z"
    />
  </Svg>
);

// Cloud SVG - small
export const SmallCloud = styled(Cloud)`
  height: 45px;
  width: auto;
`;

// Circle SVG - standard
export const Circle = ({ className, _d }) => (
  <Svg viewBox="0 0 67 67" fill="none" className={className}>
    <circle cx="33.5" cy="33.5" r="32" stroke="currentColor" strokeWidth="3" />
    <path fillRule="evenodd" clipRule="evenodd" d={_d} fill="currentColor" />
  </Svg>
);

export const TripAdvisorLogo = () => (
  <Svg viewBox="0 0 872.22 872.22">
    <title>Tripadvisor_Logo_circle-green_RGB</title>
    <circle fill="#34e0a1" cx="436.11" cy="436.11" r="436.11" />
    <path
      d="M818.35,463.9l52.5-57.12H754.46a363.57,363.57,0,0,0-409.1,0H228.67l52.5,57.12A160.45,160.45,0,1,0,498.32,700.16l51.44,56,51.41-55.93a159.81,159.81,0,0,0,109,42.74c88.61,0,160.51-71.84,160.51-160.45A160.07,160.07,0,0,0,818.35,463.9ZM389.28,691.06A108.58,108.58,0,1,1,497.86,582.48,108.58,108.58,0,0,1,389.28,691.06ZM549.76,579.35c0-71.45-52-132.76-120.51-159a313.36,313.36,0,0,1,241,0C601.72,446.6,549.76,507.9,549.76,579.35ZM710.17,691.06A108.58,108.58,0,1,1,818.75,582.48,108.58,108.58,0,0,1,710.17,691.06Zm0-165.5a56.92,56.92,0,1,0,56.92,56.92A56.92,56.92,0,0,0,710.17,525.56Zm-264,56.92a56.92,56.92,0,1,1-56.92-56.92A56.91,56.91,0,0,1,446.2,582.48Z"
      transform="translate(-113.62 -113.84)"
    />
  </Svg>
);

export const FacebookLogo = () => (
  <Svg viewBox="0 0 14222 14222">
    <circle cx="7111" cy="7112" r="7111" fill="#1977f3" />
    <path
      d="M9879 9168l315-2056H8222V5778c0-562 275-1111 1159-1111h897V2917s-814-139-1592-139c-1624 0-2686 984-2686 2767v1567H4194v2056h1806v4969c362 57 733 86 1111 86s749-30 1111-86V9168z"
      fill="#fff"
    />
  </Svg>
);
